import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as InfoImage } from 'assets/images/icons/info.svg';
import { PatientIntakeContext } from 'components/contexts/PatientIntakeContext';

const IntakeHeader = ({pageName, setShowApptInfoModal }) => {
    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);

    const useStyles = makeStyles((theme) => ({
        apptInfoStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor:"pointer"
        },
        infoText: {
            color: "#3175F6",
            fontSize: "14px !important",
            fontWeight: "600 !important"
        }
    }))

    const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent={"space-evenly"}>
        <Grid item xs={11} marginY={1}>
            <Typography variant="h6" component="h6" className="stepStyle">
                {t('PatientIntake.Step')} {intake?.page + 1}/{intake?.requiredSteps?.length}
            </Typography>
        </Grid>
        <Grid item xs={11} marginY={1}>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h5" className="pageNameStyle">
                        {intake?.page + 1}. {pageName}
                    </Typography>
                </Grid>
                <Grid item xs={6} className={classes.apptInfoStyle} onClick={() => setShowApptInfoModal(true)}>
                    <InfoImage /> &nbsp;
                    <Typography variant="h6" component="h6" className={classes.infoText}>
                        {t('PatientIntake.ApptInfo')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    );
};

IntakeHeader.propTypes = {
    pageName: PropTypes.string.isRequired,
    setShowApptInfoModal: PropTypes.func.isRequired
};

export default IntakeHeader;
