import normalize from 'json-api-normalizer';
import { isEmpty } from 'lodash';
import * as types from './actionTypes';
import * as providerApi from '../../api/providerApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function loadProvidersSuccess({
  providers,
  locations,
  schedules,
  meta,
}) {
  return {
    type: types.FETCH_PROVIDERS_SUCCESS,
    providers,
    locations,
    schedules,
    meta,
  };
}

export function beginLoadProvider() {
  return { type: types.BEGIN_FETCH_PROVIDER };
}

export function loadProviderSuccess({ provider }) {
  return { type: types.FETCH_PROVIDER_SUCCESS, provider };
}

export function loadProviderNotFound() {
  return { type: types.PROVIDER_NOT_FOUND };
}

export function loadProvider(id, permalink, context = 'patient') {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    dispatch(beginLoadProvider());
    try {
      let provider = null;
      let data = null;
      if (!Number.isNaN(Number(id))) {
        // If id is number, try to load provider with id
        // To handle new refresh link, in the format of https://host/providers/[id]
        data = await providerApi.getProvider(id, context);
        if (data.data) {
          provider = normalize(data);
        }
      } else if (!isEmpty(permalink)) {
        // If id is not number, try to load provider with permalink
        // To handle legacy link, in the format of https://host/provider/[permalink]
        const searchParams = {
          permalink,
          ignore_scheduling_restrictions: true,
        };
        data = await providerApi.searchProviders(searchParams);
        if (data && data.data[0]) {
          // Add permalink to payload since api doesn't return permalink
          data.data[0].attributes.permalink = permalink;
          provider = normalize(data);
        }
      }

      if (provider) {
        const action = loadProviderSuccess({
          provider,
        });
        dispatch(action);
      } else {
        dispatch(loadProviderNotFound());
      }
    } catch (error) {
      dispatch(loadProviderNotFound());
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadProviders(page = 1, pageSize = 4) {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const data = await providerApi.getProviders(page, pageSize);
      const normalizedData = normalize(data);
      const { providers, locations, schedules } = normalizedData;
      const actionParams = {
        providers,
        locations,
        schedules,
        meta: {
          page,
          responseMeta: data.meta,
        },
      };
      const action = loadProvidersSuccess(actionParams);
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
