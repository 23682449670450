import React, { useContext } from "react";
import { Stepper, Step, StepButton } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import { ReactComponent as ValidationImage } from 'assets/images/icons/unlock.svg';
import { ReactComponent as ValidationActiveImage } from 'assets/images/icons/unlock_active.svg';
import { ReactComponent as PaymentImage } from 'assets/images/icons/card.svg';
import { ReactComponent as PaymentActiveImage } from 'assets/images/icons/card_active.svg';
import { ReactComponent as InsuranceImage } from 'assets/images/icons/shield.svg';
import { ReactComponent as InsuranceActiveImage } from 'assets/images/icons/shield_active.svg';
import { ReactComponent as CircleImage } from 'assets/images/icons/check-circle.svg';
import { ReactComponent as DocsImage } from 'assets/images/icons/user.svg';
import { ReactComponent as DocsActiveImage } from 'assets/images/icons/user-rectangle.svg';
import "./PatientIntakePage.scss";

const stepIcons = [
    { title: 'validation', icon: <ValidationImage />, activeIcon: <ValidationActiveImage /> },
    { title: 'insurance', icon: <InsuranceImage />, activeIcon: <InsuranceActiveImage /> },
    { title: 'payment', icon: <PaymentImage />, activeIcon: <PaymentActiveImage /> },
    { title: 'drivers-license', icon: <DocsImage />, activeIcon: <DocsActiveImage /> },
    { title: 'other', icon: <DocsImage />, activeIcon: <DocsActiveImage /> },
    { title: 'lab', icon: <DocsImage />, activeIcon: <DocsActiveImage /> },
];

const stepStyle = {
    "& .MuiStepConnector-root": {
        top: "20px",
    },
    "& .MuiStepConnector-line": {
        borderTopStyle: "dotted"
    }
}

const StepperComponent = () => {

    const intake = useContext(PatientIntakeContext);

    const useStyles = makeStyles(() => ({
        iconBorder: {
            position: 'relative',
            border: "1px solid #c3c3c3",
            padding: "8px",
            borderRadius: "8px"
        },
        completeIconStyle: {
            position: 'absolute',
            top: 0,
            left: 35
        }
    }));
    const classes = useStyles();

    const StepIcon = ({ state, index, name }) => (
        <div className={classes.iconBorder}>
            {state === "none" && stepIcons.find(i => i.title === name)?.icon}
            {(state === "active" || state === "completed") && stepIcons.find(i => i.title === name)?.activeIcon}
            {index < intake.page && (name === 'validation' ||  name === 'insurance' || name === 'payment') ? <div className={classes.completeIconStyle}>{<CircleImage />}</div> : 
                index < intake.page && (name === 'drivers-license') && (intake?.intakeInfo?.[name]?.FrontImageName && intake?.intakeInfo?.[name]?.BackImageName) ? <div className={classes.completeIconStyle}>{<CircleImage />}</div> :
                index < intake.page && (name === 'other' || name === 'lab') && (intake?.intakeInfo?.[name]?.FrontImageName || intake?.intakeInfo?.[name]?.BackImageName) ? <div className={classes.completeIconStyle}>{<CircleImage />}</div> :  null}
        </div>
    );

    return (
        <Stepper activeStep={intake?.page} alternativeLabel sx={stepStyle} >
            {intake?.requiredSteps?.map((label, index) => (
                <Step key={label?.documentTypeName}  >
                    <StepButton onClick={() => intake?.setPageNum(index)}
                        icon={<StepIcon index={index} name={label?.documentTypeCode} state={index === intake?.page ? "active" : index < intake?.page ? "completed" : "none"} />}
                    />
                </Step>
            ))}
        </Stepper>
    )
}

export default StepperComponent;