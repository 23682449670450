import { Box, Grid, Typography, Button, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import BrandingButton from "components/common/BrandingButton";
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import IntakeHeader from "./IntakeHeader";
import AppointmentInfoModal from "./AppointmentInfoModal";
import { ReactComponent as SuccessImage } from 'assets/images/icons/check.svg';
import { ReactComponent as PayImage } from 'assets/images/icons/pay.svg';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import IntakeStripeForm from "./IntakeStripeForm";
import { getCurrency } from 'utils/helpers';
import { createTransaction, payLater } from "api/patientIntakeApi";

import "./PatientIntakePage.scss";

const PaymentPage = () => {

    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [showApptInfoModal, setShowApptInfoModal] = useState(false);
    const [paymentOpen, setPaymentOpen] = useState(false);
    const [sessionKeys, setSessionKeys] = useState({});

    const useStyles = makeStyles((theme) => ({
        uploadButton: {
            paddingInline: "18px",
            float: "right",
            borderRadius: "8px",
        },
        arrowBtn: {
            paddingLeft: "5px"
        },
        amountInfoStyle:{
            color:"#333333",
            fontSize:"17px !important",
            lineHeight:"19px !important",
            maxWidth:"240px !important"
        },
        amountStyle:{
            color:"#222222",
            fontSize:"35px !important",
            lineHeight:"40px !important"
        },
        container:{
            justifyContent:"center !important",
            alignItems:"center !important",
        },
        successTextStyle:{
            color:"#222222",
            fontSize:"14px !important",
            lineHeight:"20px !important",
            fontWeight:"600 !important"
        },
        backButton: {
            paddingInline: "18px",
            textTransform: "none !important",
        },
        backArrowBtn:{
            paddingRight: "5px"
        },
        grid: {
            display: "flex"
        },
        errorContainer:{
            borderRadius: "8px !important", 
            backgroundColor:"#EB5757 !important", 
            paddingInline:"10px",
            marginTop: "15px"
        },
        errorStyle:{
            color:"#FFFFFF", 
            fontSize:"12px !important"
        }
       
    }));
    const classes = useStyles();
   

    const handleClick = async () => {
        const data = await payLater(intake?.secureId, intake?.secureToken)
        if(data?.isSuccess){
            intake.nextPage();
        }else{
            setErrorMessage(data?.errorMessage)
        }
    }

    const handleRadioChange = (event) => {
        intake.setPayMethod(event.target.value);
    };

    const handleBackBtn = async () => {
        intake.setShowInsuranceForm(true);
        intake.prevPage();
    }

    const handleSuccessNextBtn = async () => {
        intake.nextPage();
    }

    const handleSuccessBackBtn = async () => {
        intake.prevPage();
    }

    const callPay = async () => {
        const data = await createTransaction(intake?.secureId, intake?.secureToken)

        if(data?.isSuccess){
            setSessionKeys(data?.sessionDetails);
            setPaymentOpen(true);
        }else{
            setErrorMessage(data?.errorMessage)
        }
    };

    const renderStripe = e => {
        const stripePromise = loadStripe(sessionKeys?.publishableKey, {
            stripeAccount: sessionKeys?.providerUserId,
        });
        return (
          <Elements stripe={stripePromise}>
            <IntakeStripeForm
                show={paymentOpen}
                toggleModal={e => {
                    setPaymentOpen(false);
                }}
                sessionKeys={sessionKeys}
                appointmentCurrency={getCurrency("USD")}
                appointmentCost={intake?.copayInfo?.copayAmount?.toString()}
                handleClose={e => {
                    setPaymentOpen(false);
                }}
                emailAddress={intake?.intakeInfo?.validation?.email}
                callVisit={async e => {
                    await intake.setCopayInfo(prevCopayInfo => ({
                            ...prevCopayInfo,
                            isCopayCollected: true
                    }));
                    setPaymentOpen(false);
                }}
                secureId={intake?.secureId}
                secureToken={intake?.secureToken}
                isRegForm={false}
            />
          </Elements>
        );
    };

    return (
        <Grid container alignItems="center" justifyContent="center">
            {errorMessage &&
                <Grid item xs={12} >
                    <Box component={"div"} className={classes.errorContainer}>
                        <Typography className={classes.errorStyle}>{errorMessage}</Typography>                                      
                    </Box>
                </Grid>
            }
            <Box
                component="div"
                sx={{ flexGrow: 1, marginTop: 3 }}
            >
                    <IntakeHeader pageName={"Payment"} setShowApptInfoModal={setShowApptInfoModal} />
                    {intake?.copayInfo?.isCopayCollected ? 
                        <Grid container >
                            <Grid item xs={12}  marginY={1}>
                            <Grid container direction={"column"}  className={classes.container}>
                                <Grid item xs={12}  marginY={1}>
                                    <SuccessImage />
                                </Grid>
                               
                                <Grid item xs={12}  marginY={1}>
                                    <Typography className={classes.successTextStyle} >
                                    {t('PatientIntake.PaymentSuccess')}!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} marginY={1}>
                                    <Typography className={classes.successTextStyle} >
                                    {t('PatientIntake.Amount')}: ${Number(intake?.copayInfo?.copayAmount).toFixed(2)}
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                            </Grid>
                            <Grid item xs={11} marginY={2} justifyContent={"space-between"} className={classes.grid}>
                                <Button
                                    type="button"
                                    className={classes.backButton}
                                    variant="outlined"
                                    onClick={handleSuccessBackBtn}
                                >
                                    <ArrowBack fontSize="small" className={classes.backArrowBtn}  /> {t('PatientIntake.Back')}
                                </Button>
                                <BrandingButton
                                        type="button"
                                        className={classes.uploadButton}
                                        onClick={handleSuccessNextBtn}
                                    >          
                                        {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                                    </BrandingButton>
                            </Grid>
                        </Grid> 
                        : 
                        <Grid container >
                            <Grid item xs={12}  marginY={1}>
                                <Grid container  direction={"column"}  className={classes.container}>
                                    <Grid item xs={8}  marginY={1}>
                                        <Typography className={classes.amountInfoStyle} >
                                        {t('PatientIntake.PayInfo')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} marginY={1}>
                                        <Typography className={classes.amountStyle} >
                                            ${Number(intake?.copayInfo?.copayAmount).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid> 
                            </Grid>
                            <Grid item xs={12}  marginY={1} marginX={2}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    value={intake?.payMethod}
                                    onChange={handleRadioChange}
                                >
                                    <FormControlLabel value="payNow" control={<Radio />} label={t('PatientIntake.PayNow')}  className="stepStyle"/>
                                    <FormControlLabel value="payLater" control={<Radio />} label={t('PatientIntake.PayLater')} className="stepStyle" />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={11} marginY={2} justifyContent={"space-between"} className={classes.grid}>
                                <Button
                                    type="button"
                                    className={classes.backButton}
                                    variant="outlined"
                                    onClick={handleBackBtn}
                                >
                                    <ArrowBack fontSize="small" className={classes.backArrowBtn}  /> {t('PatientIntake.Back')}
                                </Button>
                                { intake?.payMethod === "payNow" ? 
                                    <BrandingButton
                                        type="button"
                                        className={classes.uploadButton}
                                        onClick={callPay}
                                    >          
                                        {t('PatientIntake.Pay')} <PayImage className={classes.arrowBtn} />
                                    </BrandingButton> : 
                                    <BrandingButton
                                    type="button"
                                    className={classes.uploadButton}
                                    onClick={handleClick}
                                    >          
                                        {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                                    </BrandingButton>
                                }
                            </Grid>
                        </Grid> 
                    }
            </Box>
            <AppointmentInfoModal
                show={showApptInfoModal}
                onClose={() => setShowApptInfoModal(false)}
            />
             {sessionKeys?.publishableKey && renderStripe()}
        </Grid>
    )
}

export default PaymentPage;