import React, { useContext, useState } from "react";
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import { TextField, Box, Grid, Typography, Alert } from "@mui/material";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@material-ui/core';
import "react-datepicker/dist/react-datepicker.css";
import { ArrowForward } from "@material-ui/icons";
import BrandingButton from "components/common/BrandingButton";
import { makeStyles } from '@material-ui/core/styles';
import { validateUser } from "api/patientIntakeApi";

const ValidationPage = () => {
    const [t] = useTranslation();
    const value = useContext(PatientIntakeContext);
    const [emailValid, setEmailValid] = useState(false);
    const [dobValid, setDobValid] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const validation = value?.intakeInfo?.validation;

    const btnDisbaled =
        validation?.firstName?.length > 0 &&
        validation?.lastName?.length > 0 &&
        validation?.email?.length > 0 &&
        !emailValid &&
        validation?.dob !== null &&
        moment(validation?.dob) < moment() &&
        moment(validation?.dob) > moment("1900-01-01")

    const useStyles = makeStyles((theme) => ({
        nextButton: {
            paddingInline: "18px",
            float: "right",
            borderRadius: "8px",
            opacity: !btnDisbaled ? 0.6 : 1,
            pointerEvents: !btnDisbaled ? "none" : ""
        },
        arrowBtn: {
            paddingLeft: "5px"
        },
        text: {
            fontSize: "20px"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        input: {
            "&:valid": {
                backgroundColor: "yellow"
            },
            "&:invalid": {
                backgroundColor: "red"
            }
        }
    }));

    const classes = useStyles();

    const handleClick = async () => {
        value.setTokenExpired(false)
        const formData = {
            "first_name": validation.firstName,
            "last_name": validation.lastName,
            "email": validation.email,
            "date_of_birth": validation.dob
        }

        try {
            const data = await validateUser(value?.secureId, formData);
            if (!data?.isValid) {
                setErrorMessages(data?.validationErrors)
            } else if (data?.isValid && data?.validationErrors?.length > 0) {
                value.setApptInfo(data?.appointmentInfo)
                value.setPageNum(value?.requiredSteps?.length + 1);
            } else if (data?.isValid && data?.validationErrors?.length === 0) {
                value.setSecureToken(data?.token);
                value.setApptInfo(data?.appointmentInfo)
                value.setCopayInfo(data?.copayInfo)
                //value.setIsCoPayCollectedInRegForm(data?.copayInfo?.isCopayCollected)
                value.setShowInsuranceForm(false);
                value.nextPage()
            }
        } catch (e) {
            console.log(e);
        }
    }

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const validateEmail = (e) => {
        if (e.target?.value && e.target.value.match(isValidEmail)) {
            setEmailValid(false);
            value.setValidationInfo("email", e.target.value)
        } else {
            setEmailValid(true);
        }
    }

    const validateDateofBirth = (e) => {
        if (e.target?.value !== null &&
            parseInt(moment(e.target?.value).format("X")) < parseInt(moment().format("X")) &&
            parseInt(moment(e.target?.value).format("X")) > parseInt(moment("01/01/1900", "DD/MM/YYYY").format("X"))) {
            setDobValid(false);
            value.setValidationInfo("dob", e.target.value)
        } else {
            setDobValid(true);
        }
    }

    return (
        <>
            {errorMessages?.length > 0 && (
                <Alert severity="error" sx={{ margin: 2, }}>
                    {errorMessages?.map(err => (
                        <FormHelperText error key={err}>{t(err)} </FormHelperText>
                    ))}

                </Alert>
            )}
            {value?.tokenExpired && (
                <Alert severity="error" sx={{ margin: 2, }}>
                    <FormHelperText error>{t("PatientIntake.TokenExpired")}</FormHelperText>
                </Alert>
            )}
            <Grid container >
                <Box
                    className="validation-page"
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{ flexGrow: 1 }}
                >

                    <Grid container alignItems="center" justifyContent={"space-evenly"}>
                        <Grid item xs={11} marginY={1}>
                            <Typography variant="h6" component="h6" className="stepStyle">
                                {t('PatientIntake.Step')} 1/{value?.requiredSteps?.length}
                            </Typography>
                        </Grid>

                        <Grid item xs={11} marginY={1}>
                            <Typography variant="h5" component="h5" className="pageNameStyle">
                                1. {t('PatientIntake.Validation')}
                            </Typography>
                        </Grid>

                        <Grid item sm={5} xs={5} marginY={1}>
                            <TextField
                                className="form-item"
                                label={t('PatientIntake.FirstName')}
                                size="small"
                                fullWidth
                                required
                                onChange={(e) => value.setValidationInfo("firstName", e?.target?.value?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ""))}
                                value={validation?.firstName}
                            />
                        </Grid>

                        <Grid item sm={5} xs={5} marginY={1}>
                            <TextField
                                label={t('PatientIntake.LastName')}
                                className="form-item"
                                size="small"
                                fullWidth
                                required
                                onChange={(e) => value.setValidationInfo("lastName", e?.target?.value?.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ""))}
                                value={validation?.lastName}
                            />
                        </Grid>

                        <Grid item xs={11} sm={5} marginY={2} position={"relative"} className="datepicker">
                            <TextField
                                size="small"
                                fullWidth
                                id="date-picker-dob"
                                required
                                variant="outlined"
                                label={t('PatientIntake.DateOfBirth')}
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{ min: "1900-01-01", max: moment().format("YYYY-MM-DD") }}
                                value={validation?.dob}
                                onChange={(e) => {
                                    e.persist(); setDobValid(false); value.setValidationInfo("dob", e.target.value)
                                }}
                                onBlur={validateDateofBirth}
                            />
                            {dobValid && (
                                <FormHelperText error>{t('PatientIntake.InvalidBirthdate')}</FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={11} sm={5} marginY={2} position={"relative"}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                className="form-item"
                                label={t('PatientIntake.Email')}
                                onBlur={validateEmail}
                                onChange={(e) => { setEmailValid(false); value.setValidationInfo("email", e.target.value) }}
                                value={validation?.email}
                            />
                            {emailValid && (
                                <FormHelperText error>{t('PatientIntake.InvalidEmail')}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={11} marginY={1} justifyContent={"flex-end"}>
                            <BrandingButton
                                type="button"
                                className={classes.nextButton}
                                onClick={handleClick}
                            >
                                {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                            </BrandingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
};
export default ValidationPage;
